<template>
  <!-- <v-layer> -->
  <div>
    <v-image
      ref="petImage"
      :config="{
        name: 'petImage',
        keepRatio: true,
        resizeEnabled: true,
        draggable: getImageConfig.draggable,
        rotation: getImageConfig.rotation,
        scaleX: getImageConfig.scale.x,
        scaleY: getImageConfig.scale.y,
        x: getImageConfig.x,
        y: getImageConfig.y,
        width: getImageConfig.width,
        height: getImageConfig.height,
        image: getPetImage,
      }"
      @mousedown="canvasStickerClick"
      @touchstart="canvasStickerClick"
      @transformend="handleTransformEnd"
      @dragend="handleTransformEnd"
    />
    <!-- </v-layer> -->
  </div>
</template>

<script>
export default {
  name: "PetImageLayer",
  data() {
    return {};
  },
  props: {
    petImage: {
      name: "petImage",
      draggable: true,
      rotation: 0,
      scale: { x: 1, y: 1 },
      x: 0,
      y: 0,
      image: null,
    },
    image: null,
  },
  mounted() {},
  methods: {
    canvasStickerClick(e) {
      this.$emit("stickerClick", e);
    },
    handleTransformEnd(e) {
      this.$emit("transformEnd", e);
    },
  },
  computed: {
    getImageConfig() {
      // console.log("petImage", this.petImage);
      return this.petImage;
    },
    getPetImage() {
      // console.log("pet image", this.image);
      return this.image;
    },
  },
};
</script>

<style scoped lang="scss"></style>
