<template>
  <div v-if="isActive" class="rotate-bg">
    <div class="rotate-holder">
      <div class="rotate-icon">
        <img src="../../assets/global/rotate.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isPortrait: {
      type: Boolean,
      default: false,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    inputFocus: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {},
  computed: {
    isActive() {
      return this.isPortrait && this.isMobile && !this.inputFocus;
    },
  },
};
</script>

<style lang="scss" scoped>
.rotate-bg {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  background: url("../../assets/global/bg.jpg");
  background-size: cover;
  // @media screen and (max-device-aspect-ratio: 1/1) {
  //   display: none;
  // }
}

.rotate-holder {
  flex-direction: column;
  .rotate-icon {
    height: 80vmin;
    img {
      height: 100%;
    }
  }
}
</style>
