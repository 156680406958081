<template>
  <div>
    <!-- <v-layer ref="frame"> -->
    <v-image :config="frameImageConfig" />
    <v-sprite ref="frameEffects" :config="frameSpriteConfig" />
    <!-- </v-layer> -->
  </div>
</template>

<script>
import frameTemplateImage from "../../assets/template/frame.png";
import frameSpriteImage from "../../assets/template/frame_characters_2.png";

export default {
  name: "AnimatedFrame",
  data() {
    return {
      frameImage: null,
      frameSprite: null,
    };
  },
  props: {},
  mounted() {
    this.getFrameImage();
    this.getFrameSprite();
  },
  methods: {
    getFrameImage() {
      const image = new window.Image();
      image.src = frameTemplateImage;
      image.onload = () => {
        // console.log("frameImage");
        this.frameImage = image;

        const node = this.$refs.frameEffects.getNode();
        node.start();
      };
    },
    getFrameSprite() {
      const image = new window.Image();
      image.src = frameSpriteImage;
      image.onload = () => {
        // console.log("frameSpriteImage");
        this.frameSprite = image;
      };
    },
  },
  computed: {
    frameImageConfig() {
      return {
        keepRatio: true,
        resizeEnabled: false,
        image: this.frameImage,
        x: 0,
        y: 0,
        width: 300,
        height: 393,
      };
    },
    frameSpriteConfig() {
      return {
        keepRatio: true,
        resizeEnabled: false,
        draggable: false,
        rotation: 0,
        x: 3,
        y: 2,
        scale: { x: 0.49, y: 0.49 },
        width: 300,
        height: 340,
        image: this.frameSprite,
        animation: "moving",
        animations: {
          moving: [
            1, 1, 600, 680, 1, 683, 600, 680, 1, 1365, 600, 680, 1, 2047, 600,
            680, 603, 1, 600, 680, 603, 1365, 600, 680, 603, 683, 600, 680, 603,
            2047, 600, 680, 1205, 1, 600, 680, 1807, 1, 600, 680, 1205, 683,
            600, 680, 2409, 1, 600, 680, 1205, 1365, 600, 680, 1205, 2047, 600,
            680, 1807, 683, 600, 680, 2409, 683, 600, 680, 1807, 2047, 600, 680,
            1807, 1365, 600, 680, 2409, 1365, 600, 680, 2409, 2047, 600, 680,
          ],
        },
        frameRate: 7,
        frameIndex: 0,
      };
    },
  },
};
</script>

<style scoped lang="scss"></style>
