<template>
  <div id="app">
    <Rotate
      v-if="getContent.isStandAlone"
      :is-portrait="checkPortrait"
      :is-mobile="checkMobile"
      :input-focus="inputFocus"
    />
    <div class="content" :class="{ notintro: progress !== 1 }">
      <div class="characters-bg">
        <div class="characters-left desktop-only">
          <img src="./assets/intro/characters_desktop_left.png" />
        </div>
        <div class="characters-right desktop-only">
          <img src="./assets/intro/characters_desktop_right.png" />
        </div>
      </div>
      <div class="content-holder">
        <TitleTreatment :progress="progress" :content="getContent" />
        <Intro v-if="progress === 1" :content="getContent" />
        <AddContent v-if="progress === 2" @setInputFocus="setInputFocus" />
        <Customize
          :useMP4="useMP4"
          :ffmpeg="getFfmpeg"
          :content="getContent"
          :pet-name="petName"
          :progress="progress"
          :image="petImage"
          v-if="progress >= 3"
        />
      </div>
    </div>
    <Footer
      v-if="getContent.isStandAlone"
      :is-mobile="checkMobile"
      :content="getContent"
    />
  </div>
</template>

<script>
import siteContent from "./content/content.json";

import * as Bowser from "bowser";
import vhCheck from "vh-check";
import Rotate from "./components/common/Rotate.vue";
import Footer from "./components/common/Footer.vue";

import TitleTreatment from "./components/TitleTreatment.vue";
import Intro from "./components/Intro.vue";
import AddContent from "./components/AddContent.vue";
import Customize from "./components/Customize.vue";

// https://github.com/ffmpegwasm
// import { createFFmpeg } from "@ffmpeg/ffmpeg";

export default {
  name: "App",
  components: {
    TitleTreatment,
    Intro,
    AddContent,
    Customize,
    Rotate,
    Footer,
  },
  data() {
    return {
      useMP4: true,
      useFFMPEG: true,
      ffmpeg: null,
      content: siteContent,
      progress: 1,
      petImage: null,
      petName: null,
      downloadImage: null,
      isMobile: false,
      isPortrait: false,
      isChromeIOS: null,
      inputFocus: false,
    };
  },
  mounted() {
    // this.canUseFFMPEG();
    this.canUseMP4();
    const test = vhCheck({
      redefineVh: true,
    });

    const browser = Bowser.getParser(window.navigator.userAgent);
    this.isMobile = browser.is("mobile");

    console.log(test.isNeeded, this.content);

    window.addEventListener("resize", this.resize);
    window.addEventListener("orientationchange", this.resize);
    this.resize();

    this.$nextTick(() => {
      // if (this.useFFMPEG) {
      //   this.ffmpeg = createFFmpeg({
      //     corePath: "js/ffmpeg/ffmpeg-core.js",
      //     log: true,
      //   });
      //   this.loadFFmpeg();
      // }
      if (this.content.footer.showOneTrust) this.addOneTrust();
      if (this.content.includeGTAG) this.addGTAG();
    });
  },
  methods: {
    addOneTrust() {
      let addOneTrustScript = document.createElement("script");
      addOneTrustScript.setAttribute("src", this.content.footer.oneTrustSource);
      addOneTrustScript.setAttribute("type", "text/javascript");
      addOneTrustScript.setAttribute("charset", "UTF-8");
      addOneTrustScript.setAttribute(
        "data-domain-script",
        this.content.footer.oneTrustScript
      );
      document.head.appendChild(addOneTrustScript);
    },
    addGTAG() {
      let tagScript = document.createElement("script");
      tagScript.text =
        `(function (w, d, s, l, i) {
      w[l] = w[l] || []; w[l].push({
        'gtm.start':
          new Date().getTime(), event: 'gtm.js'
      }); var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
          'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', '` +
        this.content.gtagID +
        `');`;
      document.head.appendChild(tagScript);
    },
    canUseFFMPEG() {
      console.log("canUseFFMPEG", typeof SharedArrayBuffer);
      if (typeof SharedArrayBuffer === "undefined") {
        this.useFFMPEG = false;
      }
    },
    canUseMP4() {
      const browser = Bowser.getParser(window.navigator.userAgent);

      if (typeof MediaRecorder === "undefined") {
        this.useMP4 = false;
        return;
      }

      if (!MediaRecorder.isTypeSupported("video/mp4")) {
        this.useMP4 = false;
      }
      if (
        browser.getBrowser().name === "Safari" &&
        parseInt(browser.getBrowser().version) === 15 &&
        !browser.is("mobile")
      ) {
        this.useMP4 = false;
      }
    },
    async loadFFmpeg() {
      console.log("loadFFMPEG");
      try {
        if (this.ffmpeg && !this.ffmpeg.isLoaded()) await this.ffmpeg.load();
      } catch (error) {
        this.useFFMPEG = false;
        console.error("error");
        console.error(error);
      }
    },
    nextStep() {
      this.progress++;
      // console.log("progress", this.progress);
    },
    prevStep() {
      this.progress--;
    },
    setPetImage(img) {
      // console.log("pet image set");
      this.petImage = img;
    },
    removePetImage() {
      this.petImage = null;
    },
    setPetName(name) {
      // console.log("pet name set", name);
      this.petName = name;
    },
    setDownloadImage(img) {
      // console.log("downloadImage", downloadImage);
      this.downloadImage = img;
    },
    downloadURI(uri, name) {
      var link = document.createElement("a");
      link.download = name;
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    resize() {
      document.body.height = window.innerHeight;
      if (window.innerHeight > window.innerWidth) {
        this.isPortrait = true;
      } else {
        this.isPortrait = false;
      }
    },
    setInputFocus(val) {
      console.log("inputfocus", val);
      this.inputFocus = val;
    },
  },
  computed: {
    getFfmpeg() {
      return this.ffmpeg;
    },
    checkMobile() {
      return this.isMobile;
    },
    checkPortrait() {
      return !this.isPortrait;
    },
    checkChromeIOS() {
      return this.isChromeIOS;
    },
    getContent() {
      return this.content;
    },
  },
};
</script>

<style lang="scss">
html,
body {
  height: 100%;
  margin: 0;
}
body {
  background: url("assets/global/bg.jpg");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.konvajs-content {
  touch-action: none;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
  height: calc(100vh - var(--vh, 0px));
  overflow-x: hidden;

  .content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &.notintro {
      min-height: 600px;
    }
  }
  @media screen and (max-height: 650px) {
    height: auto;
    padding-top: 20px;
    justify-content: flex-start !important;
  }
  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 600px;
  }
  @media screen and (min-aspect-ratio: 1/1) {
    // justify-content: flex-start;
  }
}
.footer-holder {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: auto;
  z-index: 10;
  pointer-events: none;
}
</style>
<style lang="scss" scoped>
.characters-bg {
  position: fixed;
  top: 0;
  left: 50%;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
  max-width: 1300px;
  transform: translateX(-50%);
  width: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: top;
    padding-top: 10vh;
  }
  .characters-left {
    position: absolute;
    left: 0;
    width: 35vw;
    max-width: 450px;
    top: 50%;
    transform: translateY(-50%);
    @media screen and (max-width: 1024px) {
      width: 30vw;
    }
  }
  .characters-right {
    position: absolute;
    right: 0;
    width: 35vw;
    top: 50%;
    transform: translateY(-50%);
    max-width: 450px;
    @media screen and (max-width: 1024px) {
      width: 30vw;
    }
  }
}
</style>