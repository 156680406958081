<template>
  <div class="footer-holder">
    <footer key="closeFooter" class="closed-footer">
      <div class="top-edge"></div>
      <div v-if="content.footer" class="holder py-1">
        <span v-for="(link, i) in content.footer.legallinks" :key="i">
          <a
            class="legalLinks_items external"
            :href="link.href"
            target="_blank"
            :style="'font-size:' + link.size"
            >{{ link.text }}</a
          ><span class="divider">|</span>
        </span>
        <span v-if="content.footer.showOneTrust">
          <a
            href="#"
            class="ot-sdk-show-settings ot-footer-btn"
            :style="
              'font-size:' +
              content.footer.oneTrustButtonText.size +
              '!important'
            "
            >{{ content.footer.oneTrustButtonText.text }}</a
          ><span class="divider">|</span>
        </span>
        <span v-if="content.footer.credits">
          <a
            href="javascript:;"
            id="credits"
            class="legalLinks_items"
            @click="show"
            :style="'font-size:' + content.footer.credits.size"
          >
            {{ content.footer.credits.text }}
          </a>
        </span>
      </div>
    </footer>
    <footer v-if="showLegal" key="openFooter" class="open-footer">
      <div class="holder py-2">
        <div class="credit-image">
          <img src="../../assets/global/billingblock.png" />
        </div>
      </div>
      <div class="holder legalLinks_rights has-text-centered mb-1">
        <a
          v-for="(link, i) in this.content.footer.creditsLinks"
          :key="i"
          class="open_legalLinks_items"
          :href="link.href"
          target="_blank"
          :style="'font-size:' + link.size"
          >{{ link.text }}</a
        >
      </div>
      <div
        class="holder pb-2 legalLinks_rights has-text-centered"
        :style="'font-size:' + this.content.footer.copyright.size"
      >
        {{ this.content.footer.copyright.text }}
      </div>
      <button class="close-button" @click="hide">
        <span
          data-v-523635a6=""
          aria-hidden="true"
          role="img"
          class="material-design-icon close-icon"
          ><svg
            fill="currentColor"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            class="material-design-icon__svg"
          >
            <path
              d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
            ></path></svg
        ></span>
      </button>
    </footer>
  </div>
</template>

<script>
export default {
  props: {
    content: null,
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showLegal: true,
      legalTimeout: null,
    };
  },
  mounted() {
    this.legalTimeout = setTimeout(() => {
      this.showLegal = false;
    }, 3000);
  },
  methods: {
    show() {
      this.showLegal = true;
    },
    hide() {
      clearTimeout(this.legalTimeout);
      this.showLegal = false;
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
.closed-footer {
  pointer-events: none;
  padding-bottom: env(safe-area-inset-bottom);

  .divider {
    margin-left: 0.25em;
    margin-right: 0.25em;
  }

  a,
  button {
    pointer-events: auto;
  }

  .top-edge {
    height: 6px;

    background: rgb(131, 75, 20);
    background: linear-gradient(
      0deg,
      rgba(209, 151, 39, 1) 0%,
      rgba(209, 151, 39, 1) 20%,
      rgba(234, 188, 69, 1) 35%,
      rgba(234, 188, 69, 1) 80%,
      rgba(253, 243, 196, 1) 95%,
      rgba(253, 243, 196, 1) 100%
    );
  }
}
.open-footer {
  padding-bottom: env(safe-area-inset-bottom);
}
.closed-footer,
.open-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: auto;
  z-index: 10;
  pointer-events: auto;

  background: rgb(152, 0, 1);
  background: linear-gradient(
    0deg,
    rgba(152, 0, 1, 1) 0%,
    rgba(226, 0, 0, 1) 70%,
    rgba(195, 0, 1, 1) 80%,
    rgba(152, 0, 1, 1) 90%,
    rgba(152, 0, 1, 1) 100%
  );

  &.show-mobile {
    background: black;
    height: auto;
  }

  .ot-footer-btn {
    font-size: unset !important;
    padding: 0 !important;
    line-height: unset !important;
    border: 0 !important;
    background: none !important;
    color: unset !important;
  }

  font-size: 10px;
  text-transform: uppercase;

  .holder {
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    padding-left: 1em;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  @media screen and (max-width: 450px) {
    font-size: 8px;
  }
  color: white;
  a {
    white-space: nowrap;
    color: white;
  }
  button {
    color: inherit;
    font-size: 100%;
    font-family: inherit;
    border: 0;
    padding: 0;
    background: none;
    white-space: nowrap;
    cursor: pointer;
  }
}

.closed-footer {
  position: relative;
}

.open-footer {
  display: flex;
  flex-direction: column;
  background: black;
  .holder {
    display: flex;
    margin: auto;
    max-width: 80vw;

    .open_legalLinks_items {
      margin: 0 0.5em;
      font-weight: bold;
    }
  }
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 2em;
    padding: 5px;
    @media screen and (max-width: 450px) {
      font-size: 3em;
      top: 2px;
      right: 2px;
      padding-top: 0;
    }
  }

  .credit-image {
    max-width: 800px;
    margin-top: 1em;
    text-align: center;
    img {
      width: 100%;
    }
    @media screen and (max-width: 450px) {
      margin-top: 1.5em;
    }
  }
}
</style>
