<template>
  <div class="content-container">
    <div class="add-content content-square">
      <div class="frame-border"><img src="../assets/global/frame.png" /></div>
      <div class="input-container red-block">
        <input
          :style="'font-size:' + content.inputPlaceholder.size"
          :placeholder="content.inputPlaceholder.text"
          class="pet-name montserrat"
          type="text"
          ref="petName"
          name="petName"
          v-on:keyup="updatetPetName"
          @focus="$emit('setInputFocus', true)"
          @blur="$emit('setInputFocus', false)"
          maxlength="20"
        />
      </div>
      <div class="frame-break">
        <img src="../assets/global/frame_divider.png" />
      </div>
      <div class="upload-container">
        <img class="user-image" v-if="imageURL" :src="imageURL" />
        <div class="container-shadow"></div>
        <img
          v-if="!imageURL"
          @click="$refs.file.click()"
          class="upload-cta"
          :class="{ active: legalAgree }"
          src="../assets/upload/upload_cta.png"
        />
        <div v-if="imageURL" class="edit-icon delete btn" @click="deleteImage">
          <img src="../assets/global/icons/icon_delete.png" />
        </div>
        <input
          class="upload-input"
          type="file"
          @change="uploadFile"
          ref="file"
          accept="image/png, image/jpeg"
        />
        <div
          v-if="!imageURL"
          class="legal-txt montserrat light"
          :style="'font-size:' + content.legalCheckbox.size"
        >
          <label>
            <input
              type="checkbox"
              name="legal"
              ref="legal"
              v-model="legalAgree"
            />
            <span class="checkmark"></span>
            {{ content.legalCheckbox.text01 }}
            <div class="show-privacy" @click="showPrivacy(true)">
              {{ content.legalCheckbox.text02 }}
            </div>
          </label>
        </div>
      </div>
    </div>
    <div class="navigation">
      <div class="nav-col">
        <div class="nav-btn hidden">
          <img src="../assets/global/icons/nav_arrow_prev.png" />
        </div>
      </div>
      <div class="progress">
        <img src="../assets/global/icons/dot_selected.png" />
        <img src="../assets/global/icons/dot_unselected.png" />
        <img src="../assets/global/icons/dot_unselected.png" />
      </div>
      <div class="nav-col">
        <div class="nav-btn" :class="{ disabled: !isValid }" @click="nextStep">
          <img src="../assets/global/icons/nav_arrow_next.png" />
        </div>
      </div>
    </div>
    <div class="badword-modal" v-if="badWord">
      <div class="modal-content red-block montserrat black">
        <div class="content-holder">
          <div class="title" :style="'font-size:' + content.badWordModal.size">
            {{ content.badWordModal.text }}
          </div>
        </div>
        <div class="close-modal btn" @click="closeBadWord()">
          <img src="../assets/global/icons/icon_close.png" />
        </div>
      </div>
    </div>
    <div class="legal-modal" v-if="privacyModal">
      <div class="modal-content red-block montserrat black">
        <div class="content-holder">
          <div class="legal-content">
            <div
              class="legal-scroll"
              :style="'font-size:' + content.privacyPolicy.size"
              v-html="content.privacyPolicy.text"
            ></div>
          </div>
        </div>
        <div class="close-modal btn" @click="showPrivacy(false)">
          <img src="../assets/global/icons/icon_close.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import siteContent from "../content/content.json";
import badwordList from "../content/badwords.json";

export default {
  name: "AddContent",
  data() {
    return {
      content: siteContent,
      privacyModal: false,
      legalAgree: false,

      imageURL: false,
      petName: null,
      badWords: badwordList,
      badWord: false,
      inputFocus: false,
    };
  },
  methods: {
    showPrivacy(val) {
      // console.log("toggle privacy");
      this.privacyModal = val;
    },
    uploadFile() {
      this.imageURL = URL.createObjectURL(this.$refs.file.files[0]);
      const image = new window.Image();
      image.src = this.imageURL;
      image.onload = () => {
        // console.log("loaded", image);
        this.userImage = image;
        this.$parent.setPetImage(image);
      };
    },
    deleteImage() {
      this.imageURL = null;
      this.$refs.file.value = null;
    },
    updatetPetName() {
      const max = 20;
      if (this.$refs.petName.value.length > max) {
        this.$refs.petName.value = this.$refs.petName.value.substr(0, max);
      }
      this.petName = this.$refs.petName.value;
    },
    setPetName() {},
    nextStep() {
      this.checkName();
    },
    checkName() {
      this.petName = this.$refs.petName.value;
      this.badWord = false;
      if (this.badWords.includes(this.$refs.petName.value.toLowerCase())) {
        this.badWord = true;
        // console.log("bad words");
        return;
      }
      this.$parent.setPetName(this.petName);
      this.$parent.nextStep();
    },
    closeBadWord() {
      this.$refs.petName.value = "";
      this.petName = null;
      this.$parent.setPetName(null);
      this.badWord = false;
    },
  },
  computed: {
    isValid() {
      if (this.imageURL && this.petName) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
.content-container {
  @media screen and (max-width: 768px) {
    transform: scale(0.8) translateY(-10%);
  }
}
.add-content {
  .input-container {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    input {
      width: 100%;
      text-align: center;
      &:focus {
        outline: 0;
        &::placeholder {
          color: transparent;
        }
      }
    }
  }
  .upload-container {
    height: calc(100% - 50px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    background: #bc0101;
    .container-shadow {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
      box-shadow: inset #7d0502 0 0 17px 5px;
    }
    .upload-cta {
      width: 50%;
      margin: 0 auto;
      position: relative;
      pointer-events: none;
      opacity: 0.4;
      &.active {
        opacity: 1;
        cursor: pointer;
        pointer-events: auto;
      }
    }
    .upload-input {
      display: none;
    }
    .user-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
    }
    .edit-icon {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 30px;
      img {
        width: 100%;
      }
    }

    .legal-txt {
      font-size: 9px;
      color: #fff;
      position: relative;
      display: inline;
      margin-top: 20px;
      .show-privacy {
        display: inline;
        text-decoration: underline;
      }
      label {
        cursor: pointer;
      }
      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      .checkmark {
        position: absolute;
        top: 0px;
        left: -15px;
        height: 7px;
        width: 7px;
        background-color: #9f0101;
        border: 2px solid #dba03d;
        &:after {
          content: "";
          position: absolute;
          display: none;
        }
      }
      input:checked ~ .checkmark {
      }
      input:checked ~ .checkmark:after {
        display: block;
      }
      .checkmark:after {
        left: 3px;
        top: -7px;
        width: 5px;
        height: 10px;
        border: solid #fee18a;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
  }

  .pet-name {
    background: transparent;
    border: 0;
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
    &::placeholder {
      color: #fff;
      font-family: Montserrat;
    }
  }
}
.legal-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  .modal-content {
    border: 5px solid #e9c666;
    // background: url("../assets/template/modal_bg.png");
    // background-size: contain;
    width: 80vw;
    max-width: 350px;
    height: auto;
    max-height: 240px;
    padding: 10px 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    position: relative;
    .content-holder {
      height: 100%;
      width: 100%;
      color: #fee18b;
      overflow-y: scroll;
      margin: 20px;
      box-sizing: border-box;
      width: 80%;
      text-align: left;
      font-weight: 400;
      font-size: 12px;
      scrollbar-color: #eabd44 #000;
      scrollbar-width: thin;

      &::-webkit-scrollbar {
        display: block;
        width: 5px;
        padding-right: 10px;
      }
      &::-webkit-scrollbar-track {
        background: #000;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background: #eabd44;
        border-radius: 10px;
      }
      a {
        color: #fff;
      }
      .title {
        margin: 10px 0;
        font-size: 20px;
        &.download-only {
          font-size: 16px;
        }
      }
      .legal-content {
      }
    }
    .close-modal {
      position: absolute;
      top: 5px;
      right: 5px;
      width: 30px;
      z-index: 1;
      img {
        width: 100%;
      }
    }
  }
}
.badword-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  .modal-content {
    width: 80vw;
    max-width: 350px;
    height: 30vh;
    max-height: 140px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    position: relative;
    .content-holder {
      height: 100%;
      width: 100%;
      color: #fee18b;
      display: flex;
      align-items: center;
      justify-content: center;
      .title {
        font-size: 20px;
      }
    }
    .close-modal {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 30px;
      z-index: 1;
      img {
        width: 100%;
      }
    }
    .download-options {
      display: flex;
      justify-content: space-around;
      width: 100%;
      margin-top: 15px;
      .options {
        display: flex;
        align-items: flex-end;
        margin-top: 15px;
      }
      .image,
      .video {
        width: 50px;
        margin: 0 10px;
        font-size: 12px;
        img {
          width: 100%;
        }
      }
    }
    .share-txt {
      font-size: 13px;
      margin-top: 20px;
      color: #fff;
      .hashtag {
        color: #fee18b;
      }
    }
    .loading {
      display: flex;
      width: calc(100% - 13px);
      height: calc(100% - 11px);
      position: absolute;
      align-items: center;
      justify-content: center;
      background: #be0000c7;
      top: 6px;
      left: 6px;
    }
  }
}
</style>
