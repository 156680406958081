<template>
  <div class="navigation">
    <div class="nav-col">
      <div class="nav-btn prev" @click="prevStep">
        <img src="../../assets/global/icons/nav_arrow_prev.png" />
      </div>
    </div>
    <div class="progress">
      <img
        v-if="progress === 3"
        src="../../assets/global/icons/dot_selected.png"
      />
      <img src="../../assets/global/icons/dot_unselected.png" />
      <img
        v-if="progress === 4"
        src="../../assets/global/icons/dot_selected.png"
      />
      <img src="../../assets/global/icons/dot_unselected.png" />
      <img
        v-if="progress === 5"
        src="../../assets/global/icons/dot_selected.png"
      />
    </div>
    <div class="nav-col" v-if="!showDownloadPreview">
      <div class="nav-btn" @click="nextStep">
        <img src="../../assets/global/icons/nav_arrow_next.png" />
      </div>
    </div>
    <div class="nav-col" v-if="showDownloadPreview">
      <div class="nav-btn refresh" @click="refresh()">
        <img src="../../assets/global/icons/icon_refresh.png" />
      </div>
      <div class="nav-btn" @click="setDownloadOptions(true)">
        <img src="../../assets/global/icons/icon_download.png" />
      </div>
      <!-- <div class="nav-btn" @click="downloadGif">
          <img src="../assets/global/icons/icon_download.png" />
        </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Navigation",
  data() {
    return {};
  },
  props: {
    showDownloadPreview: null,
    progress: null,
  },
  mounted() {},
  methods: {
    prevStep() {
      this.$parent.prevStep();
    },
    nextStep() {
      this.$parent.nextStep();
    },
    refresh() {
      this.$emit("refresh");
    },
    setDownloadOptions(val) {
      this.$parent.setDownloadOptions(val);
    },
  },
  computed: {},
};
</script>

<style scoped lang="scss">
</style>
