<template>
  <div>
    <!-- <v-layer ref="name"> -->
    <v-text
      ref="petname"
      :config="{
        text: petName.toUpperCase() + ' ' + this.content.nameGeneratorAs.text,
        verticalAlign: 'bottom',
        y: -38,
        align: 'center',
        draggable: false,
        fill: '#fff',
        width: 300,
        height: 393,
        fontSize: this.content.nameGeneratorAs.size,
        fontFamily: this.content.fontFamily,
        fontStyle: 700,

        fillLinearGradientStartPoint: { x: 0, y: 0 },
        fillLinearGradientEndPoint: { x: 100, y: 100 },
        fillPriority: 'linear-gradient',
        fillLinearGradientColorStops: [
          0,
          '#fddb74',
          0.5,
          '#fddb74',
          1,
          '#fff9e6',
        ],
      }"
    />
    <v-text
      ref="superpetname"
      :config="{
        text: this.activeName.name.toUpperCase(),
        verticalAlign: 'bottom',
        y: -20,
        align: 'center',
        draggable: false,
        width: 300,
        height: 393,
        fontSize: this.activeName.nameSize,
        fontFamily: this.content.fontFamily,
        fontStyle: 900,

        fillLinearGradientStartPoint: { x: 0, y: 0 },
        fillLinearGradientEndPoint: { x: 100, y: 100 },
        fillPriority: 'linear-gradient',
        fillLinearGradientColorStops: [
          0,
          '#fddb74',
          0.5,
          '#fddb74',
          1,
          '#fff9e6',
        ],
      }"
    />
    <v-text
      ref="superpetname"
      :config="{
        text: this.activeName.tagline.toUpperCase(),
        verticalAlign: 'bottom',
        y: -8,
        align: 'center',
        draggable: false,
        fill: '#fff',
        width: 300,
        height: 393,
        fontSize: this.activeName.tagSize,
        fontFamily: this.content.fontFamily,
        fontStyle: 700,

        fillLinearGradientStartPoint: { x: 0, y: 0 },
        fillLinearGradientEndPoint: { x: 100, y: 100 },
        fillPriority: 'linear-gradient',
        fillLinearGradientColorStops: [
          0,
          '#fddb74',
          0.5,
          '#fddb74',
          1,
          '#fff9e6',
        ],
      }"
    />
    <!-- </v-layer> -->
  </div>
</template>

<script>
import namesList from "../../content/names.json";
import siteContent from "../../content/content.json";

export default {
  name: "NameGenerator",
  data() {
    return {
      names: namesList,
      content: siteContent,
      usedNames: [],
      activeName: {
        name: "YOUR PET",
        tagline: "",
      },
    };
  },
  props: {
    petName: null,
  },
  mounted() {
    this.getName();
  },
  methods: {
    getName() {
      const name = this.findRandomName();
      if (this.usedNames.length >= this.names.length) this.usedNames = [];

      if (this.usedNames.includes(name)) {
        this.getName();
      } else {
        this.activeName = name;
        this.usedNames.push(name);
      }
    },
    findRandomName() {
      return this.names[Math.floor(Math.random() * this.names.length)];
    },
    refresh() {
      this.getName();
    },
  },
};
</script>

<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
  li {
    display: inline-block;
    margin: 0 10px;
  }
}
a {
  color: #42b983;
}
</style>
