var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-text',{ref:"petname",attrs:{"config":{
      text: _vm.petName.toUpperCase() + ' ' + this.content.nameGeneratorAs.text,
      verticalAlign: 'bottom',
      y: -38,
      align: 'center',
      draggable: false,
      fill: '#fff',
      width: 300,
      height: 393,
      fontSize: this.content.nameGeneratorAs.size,
      fontFamily: this.content.fontFamily,
      fontStyle: 700,

      fillLinearGradientStartPoint: { x: 0, y: 0 },
      fillLinearGradientEndPoint: { x: 100, y: 100 },
      fillPriority: 'linear-gradient',
      fillLinearGradientColorStops: [
        0,
        '#fddb74',
        0.5,
        '#fddb74',
        1,
        '#fff9e6' ],
    }}}),_c('v-text',{ref:"superpetname",attrs:{"config":{
      text: this.activeName.name.toUpperCase(),
      verticalAlign: 'bottom',
      y: -20,
      align: 'center',
      draggable: false,
      width: 300,
      height: 393,
      fontSize: this.activeName.nameSize,
      fontFamily: this.content.fontFamily,
      fontStyle: 900,

      fillLinearGradientStartPoint: { x: 0, y: 0 },
      fillLinearGradientEndPoint: { x: 100, y: 100 },
      fillPriority: 'linear-gradient',
      fillLinearGradientColorStops: [
        0,
        '#fddb74',
        0.5,
        '#fddb74',
        1,
        '#fff9e6' ],
    }}}),_c('v-text',{ref:"superpetname",attrs:{"config":{
      text: this.activeName.tagline.toUpperCase(),
      verticalAlign: 'bottom',
      y: -8,
      align: 'center',
      draggable: false,
      fill: '#fff',
      width: 300,
      height: 393,
      fontSize: this.activeName.tagSize,
      fontFamily: this.content.fontFamily,
      fontStyle: 700,

      fillLinearGradientStartPoint: { x: 0, y: 0 },
      fillLinearGradientEndPoint: { x: 100, y: 100 },
      fillPriority: 'linear-gradient',
      fillLinearGradientColorStops: [
        0,
        '#fddb74',
        0.5,
        '#fddb74',
        1,
        '#fff9e6' ],
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }