<template>
  <div class="intro">
    <div class="characters-bg mobile-only">
      <img src="../assets/intro/characters.png" />
    </div>
    <div class="title">
      <img src="../assets/intro/slides.png" />
    </div>
    <div class="start-btn" @click="nextStep">
      <div class="btn-left">
        <img src="../assets/intro/start_cta_left.png" />
      </div>
      <div
        :style="'font-size:' + content.startButton.size"
        class="btn-mid montserrat black"
      >
        {{ content.startButton.text }}
      </div>
      <div class="btn-right">
        <img src="../assets/intro/start_cta_right.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Intro",
  props: {
    content: null,
  },
  methods: {
    nextStep() {
      this.$parent.nextStep();
    },
  },
};
</script>

<style scoped lang="scss">
.intro {
  width: 100vw;
  .characters-bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
      padding-top: 10vh;
    }
    .characters-left {
      position: absolute;
      left: 0;
      width: 35vw;
      top: 20vh;
      max-width: 450px;
    }
    .characters-right {
      position: absolute;
      right: 0;
      width: 35vw;
      top: 20vh;
      max-width: 450px;
    }
  }
}
.title {
  width: 80vw;
  max-width: 400px;
  margin: 2vh auto;
  img {
    width: 100%;
  }
  @media screen and (min-width: 769px) {
    max-width: 380px;
  }
}
.start-btn {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: auto;
  height: 50px;
  .btn-left,
  .btn-right {
    width: 12px;
    cursor: pointer;
  }
  .btn-mid {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 25px;
    background: url("../assets/intro/start_cta_center.png");
    background-size: auto 50px;
    color: #fee59b;
    cursor: pointer;

    text-shadow: 2px 2px 0px #21268d;
  }
  img {
    height: 100%;
    width: 100%;
  }
}
</style>
