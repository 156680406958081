import Vue from "vue";

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

import VueKonva from "vue-konva";
Vue.use(VueKonva);

import './assets/styles/main.scss';

import App from "./App.vue";

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
}).$mount("#app");
