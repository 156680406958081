<template>
  <div>
    <!-- <div class="filter-edit" v-if="progress === 4">
      <div class="edit-icon warm-filter btn" @click="this.$parent.warmFilter">
        <img src="../../assets/global/icons/icon_warmfilter.png" />
      </div>
      <div class="edit-icon cool-filter btn" @click="this.$parent.coolFilter">
        <img src="../../assets/global/icons/icon_coolfilter.png" />
      </div>
    </div> -->
    <div
      class="photo-edit red-block"
      :class="{ stickers: progress === 4 }"
      v-if="activeObject.id !== null && !downloadPreview"
    >
      <div
        :class="{ inactive: noEdit }"
        class="edit-icon rotate-right btn"
        @click="this.$parent.rotateRight"
      >
        <img src="../../assets/global/icons/icon_rotate_right.png" />
      </div>
      <div
        :class="{ inactive: noEdit }"
        class="edit-icon rotate-left btn"
        @click="this.$parent.rotateLeft"
      >
        <img src="../../assets/global/icons/icon_rotate_left.png" />
      </div>
      <div
        :class="{ inactive: noEdit }"
        class="edit-icon rotate-right btn"
        @click="this.$parent.scaleUp"
      >
        <img src="../../assets/global/icons/icon_increase_size.png" />
      </div>
      <div
        :class="{ inactive: noEdit }"
        class="edit-icon rotate-left btn"
        @click="this.$parent.scaleDown"
      >
        <img src="../../assets/global/icons/icon_decrease_size.png" />
      </div>
      <div class="edit-icon delete btn" @click="this.$parent.deleteImage">
        <img src="../../assets/global/icons/icon_delete.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PhotoEditMenu",
  data() {
    return {};
  },
  props: {
    noEdit: null,
    progress: null,
    downloadPreview: null,
    activeObject: null,
  },
  mounted() {},
  methods: {},
  computed: {},
};
</script>

<style scoped lang="scss">
.photo-edit,
.add-stickers {
  height: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  .edit-icon {
    width: 30px;
    img {
      width: 100%;
    }
    &.inactive {
      opacity: 0;
      pointer-events: none;
    }
  }
}
.photo-edit.stickers {
  position: absolute;
  right: -35px;
  height: 292px;
  top: 0;
  flex-direction: column;
  background: transparent;
  box-shadow: unset;
}
.filter-edit {
  position: absolute;
  left: -35px;
  height: 342px;
  top: 0;
  flex-direction: column;
  justify-content: flex-end;
  display: flex;
  .edit-icon {
    width: 30px;
    img {
      width: 100%;
    }
  }
}
</style>
