var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-image',{ref:"petImage",attrs:{"config":{
      name: 'petImage',
      keepRatio: true,
      resizeEnabled: true,
      draggable: _vm.getImageConfig.draggable,
      rotation: _vm.getImageConfig.rotation,
      scaleX: _vm.getImageConfig.scale.x,
      scaleY: _vm.getImageConfig.scale.y,
      x: _vm.getImageConfig.x,
      y: _vm.getImageConfig.y,
      width: _vm.getImageConfig.width,
      height: _vm.getImageConfig.height,
      image: _vm.getPetImage,
    }},on:{"mousedown":_vm.canvasStickerClick,"touchstart":_vm.canvasStickerClick,"transformend":_vm.handleTransformEnd,"dragend":_vm.handleTransformEnd}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }