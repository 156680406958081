<template>
  <div>
    <div class="title-treatment">
      <img src="../assets/global/tt.png" />
      <div class="intro-app-title montserrat black" v-if="progress === 1">
        <div :style="'font-size:' + content.introTitle.size01">
          {{ content.introTitle.text01 }}
        </div>
        <div :style="'font-size:' + content.introTitle.size02">
          {{ content.introTitle.text02 }}
        </div>
      </div>

      <div
        class="app-title montserrat black"
        :style="'font-size:' + content.appTitle.size"
        v-if="progress >= 2"
      >
        {{ content.appTitle.text }}
      </div>
    </div>
    <div v-show="progress > 1" class="progress-title montserrat extrabold">
      <template v-if="progress === 2">
        <span :style="'font-size:' + content.progressTitle01.size">
          {{ content.progressTitle01.text }}</span
        ></template
      >
      <template v-if="progress === 3">
        <span :style="'font-size:' + content.progressTitle02.size">
          {{ content.progressTitle02.text }}</span
        ></template
      >
      <template v-if="progress === 4">
        <span :style="'font-size:' + content.progressTitle03.size">
          {{ content.progressTitle03.text }}</span
        ></template
      >
      <template v-if="progress === 5">
        <span :style="'font-size:' + content.progressTitle04.size">
          {{ content.progressTitle04.text }}</span
        ></template
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "TitleTreatment",
  props: {
    content: null,
    progress: null,
  },
  mounted() {
    console.log("title", this.content.appTitle);
  },
};
</script>

<style scoped lang="scss">
.title-treatment {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 768px) {
    padding: 20px 0 0;
  }
  .intro-app-title {
    margin-top: 2vh;
    font-size: clamp(14px, 2vw, 18px);
    color: #fff;
    text-shadow: 2px 2px 0px #21268d;
    img {
      width: 20px;
      margin-bottom: -5px;
    }
  }
  .app-title {
    margin-top: 20px;
    font-size: clamp(14px, 2vw, 18px);
    color: #fff;
    text-shadow: 2px 2px 0px #21268d;
    max-width: 380px;
    img {
      width: 20px;
      margin-bottom: -5px;
    }
  }
  img {
    width: 50vmin;
    max-width: 400px;
    @media screen and (min-width: 769px) {
      max-width: 220px;
    }
  }
}
.progress-title {
  color: #21268d;
  padding: 0 0 5px;
  margin-bottom: 10px;
  max-width: 380px;
}
</style>
